<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('store.label', 2)" />
      <button
        class="small"
        @click="$store.dispatch('modals/openModal', { modal: 'editStore' , entity: {
          name: '',
          pin: '',
          adminPin: '',
          storeLayouts: [],
          noSyncStartTime: null,
          noSyncEndTime: null,
          icon: null,
        } })"
      >
        +
      </button>
    </div>

    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      @click:edit="$store.dispatch('modals/openModal', { modal: 'editStore', entity: normalizeEntity($event) })"
      @click:delete="$store.dispatch('modals/openModal', { modal: 'deleteStore', entity: $event })"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
    />

    <edit-store-modal @update="loadEntities" />
    <delete-modal type="deleteStore" entity-translation-key="store" label="name" @update="loadEntities" />
  </div>
</template>
<script>
import useLoadEntities from '@/hooks/api/loadEntities';
import useEntityFields from '@/hooks/api/fields';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import EditStoreModal from '@/components/entities/store/EditStoreModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';

export default {
  name: 'StoreIndexPage',
  components: { DeleteModal, EditStoreModal, EntityTable },
  setup() {
    const { stores: fields } = useEntityFields();
    const {
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
    } = useLoadEntities('/stores', { sort: 'name' });
    const normalizeEntity = (entity) => ({
      ...entity,
      icon: entity.icon?.['@id'],
      enabledCustomerGroups: entity?.enabledCustomerGroups?.map((l) => l['@id']) ?? [],
    });

    return {
      fields,
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      normalizeEntity,
    };
  },
};
</script>
