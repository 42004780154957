<template>
  <div>
    <div class="grid grid-cols-2 gap-4">
      <fancy-input v-model.number="model.tableNumber" type="number" :label="$t('table.number.label')" />
      <fancy-input v-model="model.name" type="text" :placeholder="model.tableNumber" :label="$t('table.name.label')" />
      <simple-dropdown v-model="model.shape" :options="tableShapes" :label="$t('table.shape.label')" />
      <simple-dropdown v-model="model.vatRateType" :options="vatRateTypes" :label="$t('table.vatRateType.label')" />
      <fancy-checkbox v-if="model.vatRateType < 100" v-model="model.allowTableOrders" :label="$t('table.allowTableOrders.label')" class="pt-6" />
    </div>
    <a href="#" class="text-sm underline" @click.prevent="$emit('click:remove', model)">
      {{ $t('general.action.removeSth', [$t('table.label')]) }}
    </a>
    <hr>
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import { useModel } from '@/hooks/model';
import { TABLE_SHAPES, VAT_RATE_TYPES } from '@/constants';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';
import FancyCheckbox from '@/components/utils/forms/FancyCheckbox.vue';

export default {
  name: 'EditTableForm',
  components: { FancyCheckbox, SimpleDropdown, FancyInput },
  props: {
    modelValue: { type: Object, default: () => ({}) },
  },
  emits: ['click:remove'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const model = useModel(props, emit);

    const tableShapes = [];
    Object.entries(TABLE_SHAPES).forEach((entry) => {
      const [key, value] = entry;
      tableShapes.push({ key, label: t(`table.shape.${key}`), value });
    });

    const vatRateTypes = [];
    Object.entries(VAT_RATE_TYPES).forEach((entry) => {
      const [key, value] = entry;
      vatRateTypes.push({ key, label: t(`table.vatRateType.${key}`), value });
    });

    return {
      model,
      tableShapes,
      vatRateTypes,
    };
  },
};
</script>
