<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editStore"
    :normalizer="saveNormalizer"
    :options="{
      properties: {
        name: '',
        pin: '',
        adminPin: '',
        vatUid: '',
        enabledCustomerGroups: [],
        receiptTextTop: '',
        receiptTextBottom: '',
        noSyncStartTime: null,
        noSyncEndTime: null,
        icon: null,
      },
      postUri: '/stores',
      label: $t('store.label')
    }"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" />
    <fancy-input v-model="properties.pin" :label="$t('store.pin.label')" :maxlength="4" :error="errors.pin" />
    <fancy-input v-model="properties.adminPin" :label="$t('store.adminPin.label')" :maxlength="4" :error="errors.pin" />
    <fancy-input
      v-model="properties.noSyncStartTime"
      step="1"
      type="time"
      :label="$t('store.noSyncStartTime.label')"
      :error="errors.noSyncStartTime"
    />
    <fancy-input
      v-model="properties.noSyncEndTime"
      step="1"
      type="time"
      :label="$t('store.noSyncEndTime.label')"
      :error="errors.noSyncEndTime"
    />
    <fancy-input
      v-model="properties.vatUid"
      :label="$t('store.vatUid.label')"
      :maxlength="15"
      :minlength="15"
      :error="errors.vatUid"
    />
    <multiselect
      v-model="properties.enabledCustomerGroups"
      :options="async (q) => loadCustomerGroups(q, properties.enabledCustomerGroups)"
      :filter-results="false"
      :error="errors.enabledCustomerGroups"
      :form-label="$t('customerGroup.label', 2)"
      :loading="loadingCustomerGroups"
      mode="tags"
      searchable
    />
    <fancy-input v-model="properties.receiptTextTop" textarea :label="$t('store.receiptTopText.label')" :error="errors.receiptTextTop" />
    <fancy-input v-model="properties.receiptTextBottom" textarea :label="$t('store.receiptBottomText.label')" :error="errors.receiptTextBottom" />
    <multiselect
      v-model="properties.icon"
      :options="async (q) => iconOptions(q, properties.icon)"
      :loading="loadingIcons"
      :filter-results="false"
      :form-label="$t('button.icon.label')"
    >
      <template #option="{ option }">
        <product-icon class="option-icon" :icon="option" /> {{ option.name }}
      </template>
      <template #singlelabel="{ value }">
        <div class="multiselect-single-label">
          <product-icon class="option-icon" :icon="value" /> {{ value.name }}
        </div>
      </template>
    </multiselect>
  </modal-form>
</template>
<script>
import useLoadOptions from '@/hooks/api/loadOptions';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import ProductIcon from '@/components/shared/ProductIcon.vue';

export default {
  components: {
    ProductIcon,
    FancyInput,
    ModalForm,
    Multiselect,
  },
  setup() {
    const {
      loadOptions: iconOptions,
      loading: loadingIcons,
    } = useLoadOptions('/images', 'name');
    const {
      loading: loadingCustomerGroups,
      loadOptions: loadCustomerGroups,
    } = useLoadOptions('/customer_groups', 'name');

    const saveNormalizer = (e) => ({
      ...e,
      noSyncStartTime: e.noSyncStartTime !== '' ? e.noSyncStartTime : null,
      noSyncEndTime: e.noSyncEndTime !== '' ? e.noSyncEndTime : null,
    });

    return {
      saveNormalizer,
      loadingCustomerGroups,
      loadCustomerGroups,
      iconOptions,
      loadingIcons,
    };
  },
};
</script>

<style scoped lang="scss">
.option-icon {
  max-width: 40px;
}
</style>
