<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editStoreLayout"
    :normalizer="normalizer"
    :options="{
      properties: {
        name: '',
        tables: [],
        sort: 0,
        store: null,
        shape: TABLE_SHAPES.SHAPE_RECTANGULAR,
      },
      postUri: '/store_layouts',
      label: $t('storeLayout.label')
    }"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" />

    <fancy-input v-model="properties.sort" type="number" :label="$t('general.field.sort')" :error="errors.sort" />

    <div class="center-between items-start">
      <h3>{{ $t('table.label', 2) }}</h3>
      <div class="flex flex-col items-end justify-start">
        <button
          class="small white"
          type="button"
          @click="addTable(properties)"
        >
          +
        </button>
        <small>Tische können nach Erstellen des Layouts auch batch-weise erstellt werden.</small>
      </div>
    </div>
    <edit-table-form
      v-for="table in properties.tables"
      :key="table['@id']"
      :model-value="table"
      @click:remove="removeTable(properties, $event)"
      @update:model-value="(e) => table = e"
    />
  </modal-form>
</template>

<script>
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import EditTableForm from '@/components/entities/store_layout/EditTableForm.vue';
import { TABLE_SHAPES, VAT_RATE_TYPES } from '@/constants';

export default {
  name: 'EditStoreLayoutModal',
  components: { EditTableForm, FancyInput, ModalForm },
  setup() {
    const addTable = (props) => {
      props.tables.push({
        tableNumber: 0,
        shape: TABLE_SHAPES.SHAPE_RECTANGULAR,
        vatRateType: VAT_RATE_TYPES.VAT_RATE_TYPE_INHOUSE,
        allowTableOrders: true,
      });
    };

    const removeTable = (props, table) => {
      const idx = props.tables.findIndex((t) => t.tableNumber === table.tableNumber);
      if (idx > -1) {
        props.tables.splice(idx, 1);
      }
    };

    const normalizer = (entity) => ({
      ...entity,
      tables: entity.tables.map((t) => ({
        ...t,
        '@id': undefined,
        id: t?.['@id'],
      })),
    });

    return {
      addTable,
      removeTable,
      normalizer,
      TABLE_SHAPES,
    };
  },
};
</script>
