<template>
  <div class="-mt-4">
    <spinner v-if="loading" color="black" />
    <template v-else>
      <entity-card
        :title="entity.name"
        :fields="fields"
        :entity="entity"
        big-heading
      >
        <template #action>
          <div class="entity-detail-page-actions">
            <button
              class="small white mr-2"
              @click="$store.dispatch('modals/openModal', { modal: 'deleteStoreLayout', entity })"
              v-text="$t('general.action.delete')"
            />
            <button
              v-t="'general.action.edit'"
              class="small mr-2"
              @click="$store.dispatch('modals/openModal', { modal: 'editStoreLayout', entity: normalizedEntity })"
            />
            <router-link
              v-t="'storeLayout.action.editArrangement'"
              class="button inline-block small mr-2"
              :to="{name: 'store-store-layouts-arrangement-id'}"
            />

            <button
              class="small white"
              @click="$store.dispatch('modals/openModal', { entity: {
                storeLayout: entity['@id'],
                rangeStart: entity.tables.length + 1,
                amount: 1,
                shape: TABLE_SHAPES.SHAPE_RECTANGULAR
              }, modal: 'batchTables' })"
            >
              {{ $t('general.action.createMultiple') }}
            </button>
          </div>
        </template>

        <template #tables>
          <ol>
            <li
              v-for="table in entity.tables"
              :key="table['@id']"
              v-text="$t('table.number.labelShort') + ' ' + table.tableNumber"
            />
          </ol>
        </template>
      </entity-card>

      <create-table-batch-modal @update="loadData" />
      <edit-store-layout-modal @update="loadData" />
      <delete-modal
        type="deleteStoreLayout"
        entity-translation-key="register"
        label="name"
        @update="$router.push({ name: 'store-store-layouts', params: { storeId: $route.params.storeId }})"
      />
    </template>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import axios from '@/services/axios';
import Spinner from '@/components/utils/spinner.vue';
import EntityCard from '@/components/shared/EntityCard.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditStoreLayoutModal from '@/components/entities/store_layout/EditStoreLayoutModal.vue';
import CreateTableBatchModal from '@/components/entities/store_layout/CreateTableBatchModal.vue';
import { TABLE_SHAPES } from '@/constants';

export default {
  name: 'RegisterDetailPage',
  components: {
    CreateTableBatchModal,
    EditStoreLayoutModal,
    DeleteModal,
    EntityCard,
    Spinner,
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const loading = ref(true);
    const loadingRules = ref(false);
    const entity = ref(null);
    const normalizedEntity = computed(() => ({
      ...entity.value,
      store: entity.value?.store?.['@id'],
    }));

    const fields = ref([
      { key: 'tables', label: t('table.label', 2) },
    ]);

    const loadData = async () => {
      loading.value = true;
      try {
        const { data: register } = await axios.get(`/store_layouts/${route.params.storeLayoutId}`);
        entity.value = register;
      } catch (err) {
        console.error(err, err.message, err.response);
      }
      loading.value = false;
    };

    loadData();

    return {
      t,
      loading,
      loadingRules,
      entity,
      normalizedEntity,
      fields,
      loadData,
      TABLE_SHAPES,
    };
  },
};
</script>
