<template>
  <div class="card">
    <div class="absolute top-2 right-2 p-2">
      <router-link
        v-t="'general.action.back'"
        class="button white inline-block small mr-2"
        :to="{name: 'store-store-layouts-id'}"
      />
      <button class="small mr-2" @click="save">
        {{ $t('general.action.save') }}
      </button>
      <button
        class="small white"
        @click="$store.dispatch('modals/openModal', { entity: {
          storeLayout: entity['@id'],
          rangeStart: entity.tables.length + 1,
          amount: 1,
          shape: TABLE_SHAPES.SHAPE_RECTANGULAR
        }, modal: 'batchTables' })"
      >
        {{ $t('general.action.createMultiple') }}
      </button>
    </div>
    <h1>{{ $t('storeLayout.arrangement.title', entity) }}</h1>
    <canvas ref="tableLayoutCanvas" class="table-layout" />
    <create-table-batch-modal @update="loadData" />
  </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { fabric } from 'fabric';
import axios from '@/services/axios';
import Spinner from '@/components/utils/spinner.vue';
import CreateTableBatchModal from '@/components/entities/store_layout/CreateTableBatchModal.vue';
import { TABLE_SHAPES } from '@/constants';

export default {
  name: 'StoreLayoutTableArrangementPage',
  components: {
    Spinner,
    CreateTableBatchModal,
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const loading = ref(true);
    const loadingRules = ref(false);
    const entity = ref(null);
    const tableLayoutCanvas = ref(null);
    const tableFabricMap = ref({});
    const normalizedEntity = computed(() => ({
      ...entity.value,
      store: entity.value?.store?.['@id'],
    }));

    // Snapping grid data
    const grid = 50;
    const canvasSize = 1000;
    const drawBoundingBoxes = false;
    let fabricCanvas = null;

    const fields = ref([
      { key: 'tables', label: t('table.label', 2) },
    ]);

    const lastBoundedAttributes = {};

    const snapToGrid = (val) => Math.round(val / grid) * grid;

    const redrawObjects = (group) => {
      const shape = group.item(0);
      const text = group.item(1);
      const w = group.getScaledWidth();
      const h = group.getScaledHeight();
      const shapeAttrs = {
        scaleX: 1,
        scaleY: 1,
        top: -h / 2,
        left: -w / 2,
      };
      if (group.table.shape === TABLE_SHAPES.SHAPE_RECTANGULAR) {
        shapeAttrs.width = w;
        shapeAttrs.height = h;
      } else if (group.table.shape === TABLE_SHAPES.SHAPE_ROUND) {
        shapeAttrs.rx = w / 2;
        shapeAttrs.ry = h / 2;
      }
      shape.set(shapeAttrs);

      const textAttrs = {
        scaleX: 1,
        scaleY: 1,
        top: 0,
        left: 0,
      };
      text.set(textAttrs);
    };

    const checkBounds = (group) => {
      // If out of bounds, reset to last known working config. If not / no longer out of bounds, store config.
      const bbox = group.getBoundingRect(false, true);
      const bboxThreshold = (grid / 2) * (3 / 2); // This is kinda random. Enforces a 1.5 times half the gridSize threshold...
      if (bbox.top < -bboxThreshold || bbox.top + bbox.height > canvasSize + bboxThreshold || bbox.left < -bboxThreshold || bbox.left + bbox.width > canvasSize + bboxThreshold) {
        group.set(lastBoundedAttributes[group.table['@id']]);
        redrawObjects(group);
        fabricCanvas.renderAll();
      }
      lastBoundedAttributes[group.table['@id']] = {
        left: group.left,
        top: group.top,
        width: group.width,
        height: group.height,
        angle: group.angle,
      };
    };

    const canvasModifiedCallback = () => {
      // Write values back to table for API
      entity.value.tables.forEach((table) => {
        const group = tableFabricMap[table['@id']];
        checkBounds(group);
        table.top = group.top;
        table.left = group.left;
        table.angle = group.angle;
        table.width = group.getScaledWidth();
        table.height = group.getScaledHeight();
      });
    };

    const canvasMovingCallback = (options) => {
      // Snapping
      const w = options.target.getScaledWidth();
      const h = options.target.getScaledHeight();
      options.target.set({
        left: snapToGrid(options.target.left + w / 2) - w / 2,
        top: snapToGrid(options.target.top + h / 2) - h / 2,
      });
      // Check bounds while moving
      checkBounds(options.target);
    };

    const canvasRenderCallback = () => {
      // Draw bounding box for debugging
      if (!drawBoundingBoxes) {
        return;
      }
      fabricCanvas.contextContainer.strokeStyle = '#FF0000';
      fabricCanvas.forEachObject((obj) => {
        if (obj.stroke) {
          return;
        }
        const bound = obj.getBoundingRect();
        fabricCanvas.contextContainer.strokeRect(
          bound.left,
          bound.top,
          bound.width,
          bound.height,
        );
      });
    };

    const canvasScalingCallback = (options) => {
      // Snapping while scaling, similar to https://stackoverflow.com/a/44160686
      // Also reset scaleX and scaleY to 1, change width and height instead (otherwise computations get messy with scale factors and font is ugly scaled)
      const { target } = options;
      const w = Math.max(snapToGrid(target.getScaledWidth()), grid);
      const h = Math.max(snapToGrid(target.getScaledHeight()), grid);

      const groupAttrs = {
        scaleX: 1,
        scaleY: 1,
        top: snapToGrid(target.top - h / 2) + h / 2,
        left: snapToGrid(target.left - w / 2) + w / 2,
        width: w,
        height: h,
      };

      target.set(groupAttrs);
      redrawObjects(target); // Redraw child objects to group dimensions
    };

    const loadData = async () => {
      loading.value = true;
      try {
        const { data: storeLayout } = await axios.get(`/store_layouts/${route.params.storeLayoutId}`);
        entity.value = storeLayout;

        storeLayout.tables.forEach((table) => {
          if (Object.prototype.hasOwnProperty.call(tableFabricMap, table['@id'])) {
            return;
          }
          // Init shape
          const commonProps = {
            fill: '#000',
            transparentCorners: false,
          };
          let el = null;
          if (table.shape === TABLE_SHAPES.SHAPE_RECTANGULAR) {
            el = new fabric.Rect({
              ...commonProps,
              width: table.width,
              height: table.height,
            });
          } else if (table.shape === TABLE_SHAPES.SHAPE_ROUND) {
            el = new fabric.Ellipse({
              ...commonProps,
              rx: table.width / 2,
              ry: table.height / 2,
            });
          } else {
            return;
          }

          // Note: can't be an int, needs to be string
          const text = new fabric.Text(`${table.name ?? table.tableNumber}`, {
            fontSize: 30,
            fill: '#FFF',
            originY: 'center',
            originX: 'center',
            textAlign: 'center',
            top: table.height / 2,
            left: table.width / 2,
          });

          // Group el + text together
          const group = new fabric.Group([el, text], {
            left: table.left,
            top: table.top,
            angle: table.angle,
            width: table.width,
            height: table.height,
            snapAngle: 15,
            noScaleCache: false,
            lockScalingFlip: true,
            originY: 'center',
            originX: 'center',
            table,
          });

          // Disable diagonal resizing because grid snapping does not work with that
          group.setControlsVisibility({
            bl: false,
            br: false,
            tl: false,
            tr: false,
          });

          // Store element
          tableFabricMap[table['@id']] = group;
          fabricCanvas.add(group);
        });
      } catch (err) {
        console.error(err, err.message, err.response);
      }
      loading.value = false;
    };

    onMounted(async () => {
      fabricCanvas = new fabric.Canvas(tableLayoutCanvas.value);
      // Draw grid lines
      for (let i = 0; i < (1000 / grid); i++) {
        fabricCanvas.add(new fabric.Line([i * grid, 0, i * grid, canvasSize], { stroke: '#ccc', selectable: false }));
        fabricCanvas.add(new fabric.Line([0, i * grid, canvasSize, i * grid], { stroke: '#ccc', selectable: false }));
      }

      // Set canvas events and properties
      fabricCanvas.on('object:modified', canvasModifiedCallback);
      fabricCanvas.on('object:moving', canvasMovingCallback);
      fabricCanvas.on('object:scaling', canvasScalingCallback);
      fabricCanvas.on('after:render', canvasRenderCallback);
      fabricCanvas.setHeight(canvasSize);
      fabricCanvas.setWidth(canvasSize);
      fabricCanvas.backgroundColor = '#EFEFEF';
      fabricCanvas.selection = false;
      await loadData();
    });

    return {
      t,
      loading,
      loadingRules,
      entity,
      normalizedEntity,
      fields,
      loadData,
      tableLayoutCanvas,
      TABLE_SHAPES,
    };
  },
  data() {
    return {
    };
  },
  methods: {
    async save() {
      await axios.put(this.entity['@id'], { tables: this.entity.tables }, {
        headers: {
          'Content-Type': 'application/ld+json',
        },
      });
    },
  },
};
</script>
<style lang="scss">
.table-layout {
  border: 1px solid #000;
}
</style>
