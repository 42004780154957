<template>
  <div class="customer-batch-form">
    <fancy-input
      v-model="model.rangeStart"
      type="number"
      :label="$t('table.number.start')"
      :min="0"
      :step="1"
    />
    <simple-dropdown v-model="model.shape" :options="tableShapes" :label="$t('table.shape.label')" />
    <fancy-input
      v-model="model.amount"
      type="number"
      :label="$t('general.field.amount')"
      :min="0"
      :step="1"
    />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { cloneDeep } from 'lodash';
import { useI18n } from 'vue-i18n';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import { TABLE_SHAPES } from '@/constants';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';

export default {
  name: 'TableBatchForm',
  components: {
    FancyInput,
    SimpleDropdown,
  },
  props: {
    modelValue: { type: Object, default: () => ({}) },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const model = computed(() => props.modelValue);

    watch(() => cloneDeep(model), (value) => {
      emit('update:modelValue', value);
    });

    const tableShapes = [];
    Object.entries(TABLE_SHAPES).forEach((entry) => {
      const [key, value] = entry;
      tableShapes.push({ key, label: t(`table.shape.${key}`), value });
    });

    return {
      model,
      tableShapes,
    };
  },
  data() {
    return {
      TABLE_SHAPES,
    };
  },
};
</script>

<style lang="scss" scoped>
.customer-batch-form > *:not(:first-child) {
  @apply mt-4;
}
</style>
