<template>
  <div>
    <spinner v-if="loading" color="#000" />
    <template v-else>
      <div class="relative">
        <h1 v-text="entity.name" />
        <div class="entity-detail-page-actions">
          <button
            v-t="'general.action.delete'"
            class="small white mr-2"
            @click="openModal(entity, 'deleteStore')"
          />
          <button v-t="'general.action.edit'" class="small" @click="openModal(normalizedEntity, 'editStore')" />
        </div>
        <router-tabs :links="hardwareLinks">
          <router-view class="pt-4" />
        </router-tabs>
      </div>

      <edit-store-modal @update="loadData" />
      <delete-modal
        type="deleteStore"
        entity-translation-key="store"
        @update="$router.push('/stores')"
      />
    </template>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import axios from '@/services/axios';
import Spinner from '@/components/utils/spinner.vue';
import RouterTabs from '@/components/utils/RouterTabs.vue';
import EditStoreModal from '@/components/entities/store/EditStoreModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';

export default {
  name: 'StoreDetailPage',
  components: {
    DeleteModal,
    EditStoreModal,
    RouterTabs,
    Spinner,
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const entity = ref({});
    const loading = ref(true);
    const hardwareLinks = ref([
      {
        id: 'store-registers', route: { name: 'store-registers' }, params: { storeId: route.params.id }, text: t('register.label', 2),
      },
      {
        id: 'store-shifts', route: { name: 'store-shifts' }, params: { storeId: route.params.id }, text: t('shift.label', 2),
      },
      {
        id: 'store-payment-terminals',
        route: { name: 'store-payment-terminals' },
        params: { storeId: route.params.id },
        text: t('paymentTerminal.label', 2),
      },
      {
        id: 'store-customer-scanners',
        route: { name: 'store-customer-scanners' },
        params: { storeId: route.params.id },
        text: t('customerScanner.label', 2),
      },
      {
        id: 'store-scales', route: { name: 'store-scales' }, params: { storeId: route.params.id }, text: t('scale.label', 2),
      },
      {
        id: 'store-printers', route: { name: 'store-printers' }, params: { storeId: route.params.id }, text: t('printer.label', 2),
      },
      {
        id: 'store-printer-rules', route: { name: 'store-printer-rules' }, params: { storeId: route.params.id }, text: t('printerRule.label', 2),
      },
      {
        id: 'store-layouts', route: { name: 'store-store-layouts' }, params: { storeId: route.params.id }, text: t('storeLayout.label', 2),
      },
    ]);

    const loadData = async () => {
      loading.value = true;
      try {
        const { data: store } = await axios.get(`/stores/${route.params.storeId}`);
        entity.value = store;
      } catch (err) {
        console.error(err, err.message, err.response);
      }
      loading.value = false;
    };

    const normalizedEntity = computed(() => ({
      ...entity.value,
      storeLayouts: entity.value?.storeLayouts?.map((l) => l['@id']) ?? [],
    }));

    const store = useStore();
    const openModal = (e, type) => {
      store.dispatch('modals/openModal', { entity: e, modal: type });
    };

    loadData();

    return {
      entity,
      loading,
      hardwareLinks,
      loadData,
      normalizedEntity,
      openModal,
    };
  },
};
</script>

<style>
</style>
