<template>
  <modal-form
    v-slot="{ properties }"
    type="batchTables"
    :options="{
      properties: {
        storeLayout: null,
        rangeStart: null,
        shape: TABLE_SHAPES.SHAPE_RECTANGULAR,
        amount: 1,
      },
      postUri: '/tables/batch_create',
      label: $t('table.batch.createTitle'),
    }"
    @update="$emit('update')"
  >
    <table-batch-form :model-value="properties" @update:modelValue="(e) => { properties = e; }" />
  </modal-form>
</template>
<script>
import ModalForm from '@/components/shared/ModalForm.vue';
import TableBatchForm from '@/components/entities/store_layout/TableBatchForm.vue';
import { TABLE_SHAPES } from '@/constants';

export default {
  name: 'CreateTableBatchModal',
  components: {
    TableBatchForm,
    ModalForm,
  },
  emits: ['update'],
  data() {
    return {
      TABLE_SHAPES,
    };
  },
};
</script>
